
import Logo from '@/assets/images/logo.svg?inline'
import ChevronLeft from '@/assets/icons/fontawesome/light/chevron-left.svg?inline'
import ChevronRight from '@/assets/icons/fontawesome/light/chevron-right.svg?inline'

export default {
  components: {
    Logo,
    ChevronLeft,
    ChevronRight
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    backdrop: {
      type: Boolean,
      default: true
    },
    toggleClass: {
      type: String,
      default: 'h-12 px-4 py-3 border border-transparent text-lg leading-6 font-medium rounded-md focus:outline-none focus:ring transition'
    },
    popupClass: {
      type: String,
      default: 'w-full'
    }
  },
  watch: {
    $route () {
      this.close()
    },
    show (value) {
      // const orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation

      if (value && screen.width < 768 /* && (orientation === 'portrait-secondary' || orientation === 'portrait-primary') */) {
        // document.querySelector('html').classList.add('h-screen', 'overflow-hidden')
        document.querySelector('body').classList.add('h-screen', 'overflow-hidden')
      } else {
        // document.querySelector('html').classList.remove('h-screen', 'overflow-hidden')
        document.querySelector('body').classList.remove('h-screen', 'overflow-hidden')
      }
    }
  },
  mounted () {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.close()
      }
    })
  },
  methods: {
    swipeHandler (direction) {
      if (screen.width > 639) {
        return
      }

      if (direction === 'left' && this.dir === 'ltr') {
        this.close()
      }

      if (direction === 'right' && this.dir === 'rtl') {
        this.close()
      }
    },
    toggle () {
      this.$emit('toggle')
    },
    close () {
      this.$emit('close')
    }
  }
}
