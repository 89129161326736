
import * as moment from 'moment'
import collect from 'collect.js'
import {
  AisInstantSearchSsr,
  AisHits,
  AisHighlight,
  AisSearchBox,
  createServerRootMixin
} from 'vue-instantsearch'
import algoliasearch from 'algoliasearch/lite'
import Plus from '@/assets/icons/fontawesome/light/plus.svg?inline'
import Minus from '@/assets/icons/fontawesome/light/minus.svg?inline'
import Times from '@/assets/icons/fontawesome/light/times.svg?inline'
import Hotel from '@/assets/icons/fontawesome/light/hotel.svg?inline'
import Search from '@/assets/icons/fontawesome/light/search.svg?inline'
import MapMarkerAlt from '@/assets/icons/fontawesome/light/map-marker-alt.svg?inline'

const searchClient = algoliasearch('62EIHD8RRK', 'a17695bf714c6c9c063c54d8a0c2d30e')
// const searchClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)

export default {
  components: {
    Plus,
    Minus,
    Times,
    Hotel,
    Search,
    MapMarkerAlt,
    AisInstantSearchSsr,
    AisHits,
    AisHighlight,
    AisSearchBox
  },
  mixins: [
    createServerRootMixin({
      searchClient,
      indexName: 'innstant_index'
      // indexName: process.env.ALGOLIA_INDEX
    })
  ],
  // serverPrefetch () {
  //   return this.instantsearch.findResultsState(this).then((algoliaState) => {
  //     this.$ssrContext.nuxt.algoliaState = algoliaState
  //   })
  // },
  props: {
    aggregator: {
      type: String,
      default: 'travolutionary'
    },
    destination: {
      type: Object,
      default () {
        return {}
      }
    },
    start: {
      type: String,
      default: moment().locale('en').format('YYYY-MM-DD')
    },
    end: {
      type: String,
      default: moment().locale('en').add(1, 'days').format('YYYY-MM-DD')
    },
    rooms: {
      type: Array,
      default () {
        return [{
          adults: 2,
          children: []
        }]
      }
    },
    pre: {
      type: Boolean,
      default: false
    },
    blank: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      destinationError: false,
      destinationOpen: false,
      datesOpen: false,
      roomsOpen: false,
      calendarData: {
        dateRange: {
          start: this.$moment(this.start, 'YYYY-MM-DD').format('D-M-YYYY'),
          end: this.$moment(this.end, 'YYYY-MM-DD').format('D-M-YYYY')
        }
      },
      query: this.destination ? this.destination.name : '',
      checkIn: this.$moment(this.start, 'YYYY-MM-DD'),
      checkOut: this.$moment(this.end, 'YYYY-MM-DD'),
      guests: this.rooms,
      selected: this.destination,
      searching: false
    }
  },
  computed: {
    roomsCount () {
      return collect(this.guests).count()
    },
    adultsCount () {
      return collect(this.guests).sum('adults')
    },
    childrenCount () {
      return collect(this.guests).pluck('children').collapse().count()
    },
    shortDays () {
      const days = this.$moment.weekdaysShort()
      return collect(days).push(collect(days).shift()).collapse()
    },
    path () {
      if (this.aggregator === 'innstant') {
        if (this.selected.type === 'hotel') {
          return this.localePath({
            name: 'hotels-destination-countrySlug-destinationSlug-hotelSlug',
            params: {
              countrySlug: this.selected.city.country.slug,
              destinationSlug: this.selected.city.slug,
              hotelSlug: this.selected.slug
            }
          })
        } else {
          return this.localePath({
            name: 'hotels-destination-countrySlug-destinationSlug-results',
            params: {
              countrySlug: this.selected.country.slug,
              destinationSlug: this.selected.slug
            }
          })
        }
      }

      if (['hotel', 'apartment', 'hostel', 'other'].includes(this.selected.type)) {
        return this.localePath({
          name: 'hotels-place-countrySlug-hotelId',
          params: {
            countrySlug: this.selected.country.slug,
            hotelId: this.selected.id
          }
        })
      }

      return this.localePath({
        name: 'hotels-place-placeId-results',
        params: {
          placeId: this.selected.id
        }
      })
    }
  },
  watch: {
    destinationOpen (value) {
      if (value) {
        setTimeout(() => {
          if (screen.width < 640) {
            document.getElementById('destinationMobile').focus()
          } else {
            document.getElementById('destination').focus()
          }
        }, 300)
      }
    },
    datesOpen (value) {
      if (!value && !this.checkOut) {
        this.checkOut = this.$moment(this.checkIn).add(1, 'days')
        this.calendarData.dateRange.end = this.$moment(this.checkIn).add(1, 'days').format('D-M-YYYY')
      }
    }
  },
  // beforeMount () {
  //   const results = this.$nuxt.context.nuxtState.algoliaState || window.__NUXT__.algoliaState

  //   this.instantsearch.hydrate(results)
  // },
  methods: {
    toggle (element) {
      switch (element) {
        case 'destination':
          this.destinationOpen = !this.destinationOpen
          break
        case 'dates':
          this.datesOpen = !this.datesOpen
          break
        case 'rooms':
          this.roomsOpen = !this.roomsOpen
          break
      }
    },
    choseDay (e) {
      if (this.calendarData.dateRange.end) {
        if (document.querySelector('.vfc-base-start + .vfc-hide') &&
            document.querySelector('.vfc-base-start + .vfc-hide').closest('.vfc-day').querySelector('.vfc-base-start')) {
          document.querySelector('.vfc-base-start + .vfc-hide').closest('.vfc-day').querySelector('.vfc-base-start').classList.add('hidden')
        }
        if (document.querySelector('.vfc-base-end + .vfc-hide') &&
            document.querySelector('.vfc-base-end + .vfc-hide').closest('.vfc-day').querySelector('.vfc-base-end')) {
          document.querySelector('.vfc-base-end + .vfc-hide').closest('.vfc-day').querySelector('.vfc-base-end').classList.add('hidden')
        }

        if (this.$moment(e.date, 'D-M-YYYY').diff(this.checkIn, 'days') < 0) {
          this.checkIn = this.$moment(e.date, 'D-M-YYYY')
          this.checkOut = null
          this.calendarData.dateRange.start = e.date
          this.calendarData.dateRange.end = ''
        } else {
          this.checkIn = this.$moment(this.calendarData.dateRange.start, 'D-M-YYYY')
          this.checkOut = this.$moment(this.calendarData.dateRange.end, 'D-M-YYYY')
          if (screen.width > 639) {
            this.datesOpen = false
            // setTimeout(() => {
            //   this.roomsOpen = true
            // }, 100)
          }
        }
        // this.checkIn = this.$moment(this.calendarData.dateRange.start, 'D-M-YYYY')
        // this.checkOut = this.$moment(this.calendarData.dateRange.end, 'D-M-YYYY')

        if (
          // this.$moment(e.date, 'D-M-YYYY').diff(this.$moment(this.calendarData.dateRange.end, 'D-M-YYYY'), 'days') < -30 ||
          this.$moment(e.date, 'D-M-YYYY').diff(this.$moment(this.calendarData.dateRange.start, 'D-M-YYYY'), 'days') > 30
        ) {
          this.$toast.error(this.$i18n.t('Reservations longer than 30 nights are not possible'), {
            position: 'top-center'
          })
        }
      } else if (this.calendarData.dateRange.start) {
        this.checkIn = this.$moment(e.date, 'D-M-YYYY')
        this.checkOut = null
        this.calendarData.dateRange.start = e.date
        this.calendarData.dateRange.end = ''
      }
    },
    addRoom () {
      this.guests.push({ adults: 2, children: [] })
    },
    removeRoom (index) {
      this.guests.splice(index, 1)
    },
    addChildren (index) {
      this.guests[index].children.push(10)
    },
    removeChildren (index) {
      this.guests[index].children.splice(0, 1)
    },
    down () {
      // if (this.results._results.length) {
      //   this.title = this.results._results[0].title
      //   document.getElementsByClassName('dropdown-item autocomplete-result')[0].focus()
      // }
    },
    next (result) {
      // const index = this.results._results.indexOf(result) + 1
      // if (index < this.results._results.length) {
      //   this.title = this.results._results[index].title
      //   document.getElementsByClassName('dropdown-item autocomplete-result')[index].focus()
      // } else {
      //   this.title = this.results._results[0].title
      //   document.getElementsByClassName('dropdown-item autocomplete-result')[0].focus()
      // }
    },
    previous (result) {
      // const index = this.results._results.indexOf(result) - 1
      // if (index === -1) {
      //   this.selected = null
      //   this.clickTitle()
      // } else {
      //   this.title = this.results._results[index].title
      //   document.getElementsByClassName('dropdown-item autocomplete-result')[index].focus()
      // }
    },
    updateAddress (event) {
      this.query = event.name
      this.selected = {
        type: 'place',
        name: event.name,
        title: event.name,
        id: event.place_id,
        _geoloc: {
          lat: event.geometry.location.lat(),
          lng: event.geometry.location.lng()
        }
      }
      this.getPlaceDetails()
      this.destinationOpen = false
      // if (screen.width > 639) {
      //   setTimeout(() => {
      //     this.datesOpen = true
      //   }, 100)
      // }
    },
    select (result) {
      this.query = result.title
      this.selected = result
      this.destinationOpen = false
      // if (screen.width > 639) {
      //   setTimeout(() => {
      //     this.datesOpen = true
      //   }, 100)
      // }
    },
    clear () {
      this.query = ''
      this.selected = null
      document.getElementById('destination').focus()
    },
    async createSession (count = 1) {
      if (this.searching) {
        return
      }

      this.$emit('search')

      if (!this.pre) {
        return this.go()
      }

      if (this.selected && this.selected.id) {
        this.searching = true

        let params = {}

        const query = {
          check_in: this.checkIn.locale('en').format('YYYY-MM-DD'),
          check_out: this.checkOut.locale('en').format('YYYY-MM-DD'),
          country: this.country,
          currency: this.currency,
          net: this.priceDisplay === 'net' ? 1 : 0,
          query_text: this.selected.name ?? null,
          guests: this.rooms.map((r) => {
            const room = []
            for (let a = 0; a < r.adults; a++) {
              room.push('a')
            }
            for (let c = 0; c < r.children.length; c++) {
              room.push(r.children[c])
            }
            return room.toString().replace(/,/g, ':')
          }).toString().replace(/,/g, '|').replace(/:/g, ',')
        }

        if (this.aggregator === 'innstant') {
          params = {
            aggregator: 'innstant',
            type: this.selected.type === 'hotel' ? 'hotel' : 'location',
            ids: this.selected.id,
            ...query
          }
        } else if (['hotel', 'apartment', 'hostel', 'other'].includes(this.selected.type)) {
          params = {
            aggregator: 'travolutionary',
            type: 'hotel',
            ids: this.selected.id ?? null,
            ...query
          }
        } else {
          params = {
            aggregator: 'travolutionary',
            type: 'geoloc',
            query_type: this.selected.query_type,
            latitude: this.selected._geoloc.lat,
            longitude: this.selected._geoloc.lng,
            radius: 500,
            place_id: this.selected.id,
            ...query
          }
        }

        await this.$axios.$post('/v3/sessions', params).then((response) => {
          this.go(response.session)
        }).catch(() => {})
      } else {
        this.destinationError = true

        setTimeout(() => {
          this.destinationError = false
        }, 1000)
      }

      this.searching = false
    },
    getPlaceDetails () {
      this.$axios.$get('/v3/services/place/details', {
        params: {
          place_id: this.selected.id
        }
      }).then((response) => {
        this.selected.query_type = ['lodging', 'point_of_interest'].some(el => response.result.types.includes(el)) ? 'property' : 'destination'
      })
    },
    go (session = null) {
      if (this.selected && this.selected.id) {
        const data = {
          path: this.path,
          query: {
            check_in: this.checkIn.locale('en').format('YYYY-MM-DD'),
            check_out: this.checkOut.locale('en').format('YYYY-MM-DD'),
            guests: this.guests.map((r) => {
              const room = []
              for (let a = 0; a < r.adults; a++) {
                room.push('a')
              }
              for (let c = 0; c < r.children.length; c++) {
                room.push(r.children[c])
              }
              return room.toString().replace(/,/g, ':')
            }).toString().replace(/,/g, '|').replace(/:/g, ','),
            country: this.country,
            currency: this.currency,
            radius: 50000,
            latitude: this.selected._geoloc.lat,
            longitude: this.selected._geoloc.lng,
            session
          }
        }

        if (this.blank) {
          data.query.fid = this.$route.query.fid || this.$cookies.get('fid')

          return window.open(this.$router.resolve(data).href, '_blank')
        }

        this.$router.push(data)
      } else {
        this.destinationError = true

        setTimeout(() => {
          this.destinationError = false
        }, 1000)
      }
    }
  }
}
