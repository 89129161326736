
import LongArrowLeft from '@/assets/icons/fontawesome/light/long-arrow-left.svg?inline'
import LongArrowRight from '@/assets/icons/fontawesome/light/long-arrow-right.svg?inline'

export default {
  components: {
    LongArrowLeft,
    LongArrowRight
  },
  props: {
    checkIn: {
      type: [String, Object],
      default: null
    },
    checkOut: {
      type: [String, Object],
      default: null
    },
    time: {
      type: Boolean,
      default: false
    }
  }
}
