import { render, staticRenderFns } from "./Select.vue?vue&type=template&id=55974ea4&"
import script from "./Select.vue?vue&type=script&lang=js&"
export * from "./Select.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Select: require('/vercel/path0/components/common/Select.vue').default})
